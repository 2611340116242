import Vue from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardGrouped from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Grouped/index.vue";
import { mapActions, mapGetters } from "vuex";
import { formatVisibleItems } from "../../../Utils";

export default Vue.extend({
	name: "DigitalBehavior",
	props: {},
	components: { CollapsePanel, CardGrouped },
	data: () => ({}),
	created() { },
	mounted() {
		this.$nextTick(async () => { });
		
		const { iab, interest, sites, app_name, content_language, city_connection } = this.getAudience;
		this.$watch(() => [iab, interest, sites, app_name, content_language, city_connection], async () => {
			await this.$nextTick()
			const items: HTMLCollection = this.$refs.digitalbehavior.children;
			formatVisibleItems(items, [
				iab, interest, 
				sites, 
				app_name, 
				content_language, 
				city_connection
			])			
		}, { immediate: true, deep: true })
	},
	computed: {
		...mapGetters("audience", [
			"getAudience"
		]),
		...mapGetters("audience", {
			getFilters: "getFilters"
		}),
		...mapGetters("person", {
			getCountry: "getCountry"
		}),
		
		iab() { return this.getAudience.iab },
		interest() { return this.getAudience.interest },
		sites() { return this.getAudience.sites },
		app_name() { return this.getAudience.app_name },
		content_language() { return this.getAudience.content_language },
		city_connection() { return this.getAudience.city_connection },
		geoTranslate() {
			const id = this.getCountry.id;
			const default_country_id = 840;
			const country_id = isNaN(id) ? default_country_id : id;

			const geoTranslated = this.$te(`persons.v10.panelsPois.geo.${country_id}.cities`) 
				? this.$t(`persons.v10.panelsPois.geo.${country_id}.cities`)
				: this.$t(`persons.v10.panelsPois.geo.${default_country_id}.cities`);

			return this.$t(`Persons10.panelAudience.charts.titles.city_connection`, {
				city: geoTranslated
			})
		}
	},
	methods: {
		...mapActions("audience", [
			"addFilter", "removeFilter"
		]),
	},
	watch: {},
});
