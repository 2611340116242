import Vue from "vue";
// @ts-ignore
import CreateTabs from "@/views/Admin/Commons/Tabs.vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
// @ts-ignore
import TabCampaigns from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/Campaigns/index.vue";
import TabTotal from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/Total/index.vue";
import TabSocioDemographic from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/SocioDemographic/index.vue";
import TabPoisInformation from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/PoisInformation/index.vue";
import TabDate from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/Date/index.vue";
import TabResidencePlace from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/ResidencePlace/index.vue";
import TabDigitalBehavior from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/DigitalBehavior/index.vue";
import TabConnectionType from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/ConnectionType/index.vue";
import TabPoisAndMobility from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/PoisAndMobility/index.vue";
import TabGenderAndAge from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/GenderAndAge/index.vue";
import TabAttributions from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/Attributions/index.vue";
import TabRichAttributions from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/RichAttributions/index.vue";
import AudienceExport from "@/views/Admin/Persons/V10/Dependencies/Dialogs/ExportAudience/index.vue";
import FloatingActions from "@/views/Admin/Persons/V10/Dependencies/Common/FloatingActions/index.vue";
import { mapActions, mapGetters } from "vuex";
import { TypeLoading } from "@/interfaces/loading";
import { isEqual } from "lodash";
import { sleep } from "@/utils/convert";
import { AUDIENCE_ACTIONS, FloatingAction } from "@/interfaces/persons/v10/audience";

const initialPanel = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export interface IFilterData {
	type: string,
	name: string
}

export default Vue.extend({
	name: "PersonsAudience",
	props: {},
	components: {
		CreateTabs,
		TabCampaigns,
		TabTotal,
		TabSocioDemographic,
		TabPoisInformation,
		TabDate,
		TabResidencePlace,
		TabDigitalBehavior,
		TabConnectionType,
		TabPoisAndMobility,
		TabGenderAndAge,
		TabAttributions,
		TabRichAttributions,
		AudienceExport,
		FloatingActions
	},
	data: () => ({
		currentTab: 1,
		filters: [] as IFilterData[],
		savedFilters: [] as IFilterData[],
		formattedData: {},
		panel: [...initialPanel],
		savedPanel: [],
		modal: false,
		exportScreenModal: false,
		showExportAudienceModal: false
	}),
	created() { },
	mounted() { 
		this.$nextTick(async () => {
			this.currentTab = this.isTabAudience ? 1 : 2;
			this.setLoadingData(TypeLoading.loading);
			await this.initialize();
			this.setLoadingData();
			this.fetchAll();
			this.panel = [...initialPanel];
		});
	},
	computed: {
		...mapGetters("audience", {
			filterHasChanges: "filterHasChanges",
			loadingData: "loadingData"
		}),

		isDummyRich() {
			return Boolean(process.env.VUE_APP_ENABLE_STORE_ATTRIBUTION_FAKE)
		},

		isTabAudience() {
			return this.$route.name == "PersonsAudience";
		},

		getFloatingActions(): FloatingAction[] {
			return [{
				action: AUDIENCE_ACTIONS.BACK,
				icon: "mdi-arrow-left",
				disabled: false,
				loading: false,
				title: this.$t('Persons10.panelAudience.buttons.edit_poi')
			}, {
				action: AUDIENCE_ACTIONS.EXPORT_AUDIENCE,
				icon: "mdi-account-arrow-right",
				disabled: false,
				loading: false,
				title: this.$t('Persons10.panelAudience.buttons.export_audience')
			}, {
				action: AUDIENCE_ACTIONS.EXPORT_SCREEN,
				icon: "mdi-monitor-screenshot",
				disabled: this.loadingData,
				loading: this.loadingData,
				title: this.$t('Persons10.panelAudience.buttons.export_screen')
			}, {
				action: AUDIENCE_ACTIONS.FILTER,
				icon: "mdi-filter-cog",
				disabled: !this.filterHasChanges || this.loadingData,
				loading: this.loadingData,
				title: this.$t('Persons10.panelAudience.buttons.filter')
			}]
		},

		getTabItems() {
			return [
				{
					key: 0,
					tab: i18n.t("persons.tabs.pois"),
					disabled: true,
				},
				{
					key: 1,
					tab: i18n.t("persons.tabs.audience"),
					disabled: !this.isTabAudience,
				},
				{
					key: 2,
					tab: i18n.t("persons.tabs.store-attribution"),
					disabled: this.isTabAudience,
				},
			];
		},
	},
	methods: {
		...mapActions("audience", ["initialize", "fetchAll", "fetchFilters", "exportAudience"]),
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("export", ["exportPdf"]),

		handleAction(action: AUDIENCE_ACTIONS) {
			switch(action) {
				case AUDIENCE_ACTIONS.FILTER:
					this.handleClickFilter();
					break;
				case AUDIENCE_ACTIONS.EXPORT_SCREEN:
					this.handleExportScreen();
					break;
				case AUDIENCE_ACTIONS.EXPORT_AUDIENCE:
					this.handleOpenExportAudienceModal();
					break;
				case AUDIENCE_ACTIONS.BACK:
					this.handleEditPoi();
					break;
				default: 
					alert('e')
			}
		},

		handleEditPoi() {
			this.modal = true;
		},

		handleYes() {
			// this.$router.push({
			// 	name: "PersonsIndex",
			// 	query: { from: "audience" }
			// })
			// .then(() => window.location.reload());
			window.location.href = `${process.env.VUE_APP_FRONT_URL}/admin/persons/index?from=audience`;
		},

		handleNo() {
			this.modal = false;
		},

		handleClickFilter() {
			this.fetchFilters();
		},

		handleCloseExportAudienceModal() {
			this.showExportAudienceModal = false;
		},

		handleOpenExportAudienceModal() {
			this.showExportAudienceModal = true;
		},

		handleExportAudience(data) {
			this.exportAudience(data);
			this.handleCloseExportAudienceModal();
		},

		async handleExportScreen() {
			if(!isEqual(initialPanel, this.panel)) {
				this.exportScreenModal = true;
			} else {
				this.handleExportScreenYes();
			}
		},

		async handleExportScreenNo() {
			this.exportScreenModal = false;
			this.savedPanel = this.panel;
			this.panel = initialPanel;
			this.setLoadingData(TypeLoading.loading)
			await sleep(50);
			await this.exportPdf("export-audience");
			this.panel = this.savedPanel;				
			this.setLoadingData()
		},

		async handleExportScreenYes() {
			this.exportScreenModal = false;
			this.setLoadingData(TypeLoading.loading)
			await this.exportPdf("export-audience");
			this.setLoadingData()			
		},

		deleteFilter(filter) {
			const filterIndex = this.filters.findIndex(item => item == filter)
			this.filters.splice(filterIndex, 1)
		},
	},
	watch: {},
});
