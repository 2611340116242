import Vue from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardGrouped from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Grouped/index.vue";
import { mapActions, mapGetters } from "vuex";
import { formatVisibleItems } from "../../../Utils";

export default Vue.extend({
	name: "ResidencePlace",
	props: {},
	components: { CollapsePanel, CardGrouped },
	data: () => ({}),
	created() { },
	mounted() {
		this.$nextTick(async () => { });
		const { residence_barrio, residence_city } = this.getAudience;
		this.$watch(() => [residence_barrio, residence_city], async () => {
			await this.$nextTick()
			const items: HTMLCollection = this.$refs.residenceplace.children;
			formatVisibleItems(items, [
				residence_barrio,
				residence_city
			])			
		}, { immediate: true, deep: true })
	},
	computed: {
		...mapGetters("audience", [
			"getAudience"
		]),
		...mapGetters("audience", {
			getFilters: "getFilters"
		}),
		...mapGetters("person", {
			getCountry: "getCountry"
		}),
		
		residence_barrio() { return this.getAudience.residence_barrio },
		residence_city() { return this.getAudience.residence_city },
	},
	methods: {
		...mapActions("audience", [
			"addFilter", "removeFilter"
		]),

		// Get the translation of the geographies
		geoTranslate(key: "cities"|"neighborhoods") {
			const id = this.getCountry.id;
			const default_country_id = 840;
			const country_id = isNaN(id) ? default_country_id : id;
			const geoTranslated = this.$te(`persons.v10.panelsPois.geo.${country_id}.${key}`) 
				? this.$t(`persons.v10.panelsPois.geo.${country_id}.${key}`)
				: this.$t(`persons.v10.panelsPois.geo.${default_country_id}.${key}`);
			return this.$t(`Persons10.panelAudience.charts.titles.by_residence_place`, {
				name: geoTranslated
			})
		}
	},
	watch: {},
});
