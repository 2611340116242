import Vue from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardLine from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Line/index.vue";
import RichAttributionsTable from "@/views/Admin/Persons/V10/Dependencies/Tables/RichAttributions/index.vue";
import CardHeatmap from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Heatmap/index.vue";
import { mapActions, mapGetters } from "vuex";
import { isEmpty, isNumber, isUndefined, orderBy, sortBy } from "lodash";
import { GraphicEntity } from "@/models/persons/v10/Implements";
import { AttributionsItem, DailyRichAttributionsItem, TotalRichAttributionsItem } from "@/interfaces/graphic";

export default Vue.extend({
	name: "RichAttributions",
	props: {},
	components: { CollapsePanel, CardLine, CardHeatmap, RichAttributionsTable },
	data: () => ({}),
	created() { },
	mounted() {
		this.$nextTick(async () => { });
	},
	computed: {
		...mapGetters("audience", [
			"getAudience"
		]),

		rich_attributions_daily() { return this.getAudience.rich_attributions_daily },
		rich_attributions() { return this.getAudience.rich_attributions },

		showRichAttributionTable() {
			return this.rich_attributions_daily.loading || !isEmpty(this.rich_attributions_daily.source) || !isEmpty(this.rich_attributions.source)
		},
	},
	methods: {
		getRichAttributions(data: GraphicEntity<TotalRichAttributionsItem|DailyRichAttributionsItem>) {
			if(!data ||!data.source || !data.source[0]) return {
				items: [],
				headers: []
			};
			
			const firstItem = data.source[0];
			const number_keys: string[] = [];
			const string_keys: string[] = [];

			Object.entries(firstItem).map(([key, value]) => {
				if(typeof value !== "string") {
					number_keys.push(key);
				} else {
					string_keys.push(key)
				}
			})

			const items = data.source.map(item => {

				let entries = number_keys.map(key => {
					return [key, item[key] || 0];
				})

				return {
					...item,
					...Object.fromEntries(entries)
				} 
			})

			const totalRadius: {[key: string]: number} = {}

			// For store the items
			items.forEach(item => {
				number_keys.map(key => {
					if(isNumber(totalRadius[key])) {						
						totalRadius[key] += item[key]
					} else {
						totalRadius[key] = item[key]
					}
				})
			});

			// create the headers array
			let headers = string_keys.sort().map(key => ({				
				key: key,
				value: key,
				text: this.$t(`Persons10.charts.rich_attributions.${key}`),
				sortable: true
			}));
			
			// Push every item on it
			number_keys.forEach(key => {
				if(!isUndefined(totalRadius[key]) && totalRadius[key] == 0) {
					return;
				}
				
				headers.push({
					key: key,
					value: key,
					text: key,
					sortable: true
				})
			})

			console.log({
				items: orderBy(items, headers[headers.length - 1].key, "desc"),
				headers,
				totalRadius
			})
			
			// Return the data table
			return {
				items: orderBy(items, headers[headers.length - 1].key, "desc"),
				headers
			};
		}
	},
	watch: {},
});
