import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import { InfoData } from "@/interfaces/export";
import AmchartGlobal from "./global";
import { newYAxisRenderer, optionsAxis } from ".";
import { translateChartIfExist } from "@/utils/Locale";

class AmchartHeatmapBar {

	root: any = null;
	exporting: any = null;

	async dispose(id: string) {
		am5.array.each(am5.registry.rootElements, function (root: am5.Root) {
			if (root?.dom?.id == id) {
				root.dispose();
			}
		});
	}

	async setup(params: {
		id: string;
		infoData: InfoData;
		source: Array<any>;
		labels: string[]
	}) {

		await this.dispose(params.id);

		const [topSide, leftSide] = params.labels;

		// x Axis
		let xAxisData: { [key: string]: string }[] = [];

		// y Axis
		let yAxisData: { [key: string]: string }[] = [];


		params.source.forEach(item => {
			if (!xAxisData.some(currentData => currentData[topSide] == item[topSide])) {
				xAxisData.push({ [topSide]: item[topSide] })
			}

			if (!yAxisData.some(currentData => currentData[leftSide] == item[leftSide])) {
				yAxisData.push({ [leftSide]: item[leftSide] })
			}
		})

		yAxisData = yAxisData.map(data => {
			data.translation = translateChartIfExist(leftSide, data[leftSide]);

			return data;
		})

		xAxisData = xAxisData.map(data => {
			data.translation = translateChartIfExist(topSide, data[topSide]);

			return data;
		})

		if (!AmchartGlobal.isPassedSource(params.infoData.id, params.source))
			return;

		this.root = am5.Root.new(params.id);
		const data: any[] = [];

		params.source.forEach(item => data.push(Object.assign({}, { ...item })))

		this.root.setThemes([
			am5themes_Animated.new(this.root)
		]);

		// Create chart
		var chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
			focusable: true,
			panX: false,
			panY: false,
			wheelX: "none",
			wheelY: "none",
			pinchZoomX: false,
			layout: this.root.verticalLayout
		}));

		var xRenderer = am5xy.AxisRendererX.new(this.root, {
			visible: false,
			minGridDistance: 30,
			opposite: true
		});

		xRenderer.grid.template.set("visible", false);

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		var xAxis = chart?.xAxes.push(
			am5xy.CategoryAxis.new(this.root, {
				...optionsAxis,
				renderer: xRenderer,
				tooltip: am5.Tooltip.new(this.root, {}),
				categoryField: topSide
			})
		);
		xRenderer.labels.template.setAll({
			text: "{translation}",
			fontSize: 12
		});
		xAxis.data.setAll(xAxisData)

		var yRenderer = am5xy.AxisRendererY.new(this.root, {
			visible: false,
			minGridDistance: 20,
			inversed: true
		});
		yRenderer.labels.template.setAll({
			text: "{translation}"
		});
		yRenderer.grid.template.set("visible", false);

		var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(this.root, {
			// logarithmic: true,
			maxDeviation: 0,
			renderer: yRenderer,
			categoryField: leftSide
		}));
		yAxis.data.setAll(yAxisData)

		// Add series
		function makeSeries(root: am5.Root) {

			var series = chart.series.push(am5xy.ColumnSeries.new(root, {
				calculateAggregates: true,
				stroke: am5.color(0xffffff),
				clustered: false,
				xAxis: xAxis,
				yAxis: yAxis,
				categoryXField: topSide,
				categoryYField: leftSide,
				valueField: "uniques"
			}));

			series.columns.template.setAll({
				tooltipText: "{value}",
				strokeOpacity: 1,
				strokeWidth: 2,
				width: am5.percent(100),
				height: am5.percent(100)
			});

			var heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(root, {
				orientation: "horizontal",
				endColor: am5.color(0xfffb77),
				startColor: am5.color(0xfe131a)
			}));

			series.columns.template.events.on("pointerover", function (event) {
				var di = event.target.dataItem;
				if (di) {
					heatLegend.showValue(di.get("value", 0));
				}
			});

			series.setAll({
				heatRules: [{
					target: series.columns.template,
					min: am5.color(0xfffb77),
					max: am5.color(0xfe131a),
					dataField: "value",
					key: "fill"
				}]
			});

			series.data.setAll(data);

			// Make stuff animate on load
			series.events.on("datavalidated", function (ev: { target: any }) {
				let series = ev.target;
				let chart = series.chart;

				heatLegend.set("startValue", series.getPrivate("valueHigh"));
				heatLegend.set("endValue", series.getPrivate("valueLow"));
				// let xAxis = chart.xAxes.getIndex(0);

				// Calculate how we need to adjust chart height
				// let chartHeight =
				// 	series.data.length * cellSize +
				// 	xAxis.height() +
				// 	chart.get("paddingTop", 0) +
				// 	chart.get("paddingBottom", 20);

				// Set it on chart's container
				chart.root.dom.style.height = 400 + "px";
			});

			series.appear();
		}

		makeSeries(this.root);

		this.load(chart);

		// add scrollbar
		chart.set("scrollbarX", am5.Scrollbar.new(this.root, {
			visible: false
		}));


		// Make stuff animate on load
		// https://www.amcharts.com/docs/v5/concepts/animations/
		chart.appear(1000, 100);

		// if(omitExportPDF().includes(params.infoData.id)) return;

		// Add export menu
		this.exporting = await AmchartGlobal.enableExporting(
			params.id,
			this.root,
			params.source
		);

		/**
		 * Se agrega la informacion del grafico para incluir en la generacion del pdf
		 */
		AmchartGlobal.addExporting(
			await AmchartGlobal.prepareExportParams(params, this.exporting)
		);
	}

	async load(chart: am5xy.XYChart) {
		chart.appear(500, 100);
	}
}

export default new AmchartHeatmapBar();
