import Vue, { PropType } from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardCombined from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Combined/index.vue";
import { mapActions, mapGetters } from "vuex";
import { formatVisibleItems } from "../../../Utils";
import { allowedFiltrableSocioeconomic } from "../utils";

export default Vue.extend({
	name: "SocioDemographic",
	props: {},
	components: {
		CollapsePanel,
		CardCombined
	},
	data: () => ({}),
	created() { },
	mounted() {
		this.$nextTick(async () => { });
		const { age, gender, niv_socio } = this.getAudience;
		this.$watch((vm) => [vm.age, vm.gender, vm.niv_socio,], async () => {
			await this.$nextTick();
			const items: HTMLCollection = this.$refs.sociodemographic.children;
			formatVisibleItems(items, [
				gender,
				age,
				niv_socio
			])			
		}, { immediate: true, deep: true })
	},
	computed: {
		...mapGetters("audience", [
			"getAudience"
		]),
		...mapGetters("audience", {
			getFilters: "getFilters"
		}),
		...mapGetters("person", [
			"getCountry"
		]),
		
		isFiltrableSocioeconomic() {
			let id: number = this.getCountry.id;

			return allowedFiltrableSocioeconomic.includes(id);
		},

		gender() {

			const genderImages = {
				"F": require('@/assets/icons/chart-female.svg'),
				"M": require('@/assets/icons/chart-male.svg')
			}
			const { gender } = this.getAudience;

			const source = gender.source.map(item => ({
				...item,
				src: genderImages[item.name]
			}))

			return Object.assign({}, { ...gender, source })
		},

		age() {
			const ageImages = {
				"18_24": require('@/assets/icons/chart-age-18-24.svg'),
				"25_34": require('@/assets/icons/chart-age-25-34.svg'),
				"35_44": require('@/assets/icons/chart-age-35-44.svg'),
				"45_54": require('@/assets/icons/chart-age-45-54.svg'),
				"55_plus": require('@/assets/icons/chart-age-55-plus.svg')
			}

			const { age } = this.getAudience;

			const source = age.source.map(item => ({
				...item,
				src: ageImages[item.name]
			}))

			return Object.assign({}, { ...age, source })
		},

		niv_socio() {
			const socioEconomicImages = {
				"A": require('@/assets/icons/chart-economic-a.svg'),
				"A+": require('@/assets/icons/chart-economic-a-plus.svg'),
				"A-": require('@/assets/icons/chart-economic-a-less.svg'),
				"AB": require('@/assets/icons/chart-economic-ab.svg'),
				"A/B": require('@/assets/icons/chart-economic-a-b.svg'),
				"ABC1": require('@/assets/icons/chart-economic-abc1.svg'),
				"B": require('@/assets/icons/chart-economic-b.svg'),
				"B+": require('@/assets/icons/chart-economic-b-plus.svg'),
				"B-": require('@/assets/icons/chart-economic-b-less.svg'),
				"B1": require('@/assets/icons/chart-economic-b1.svg'),
				"B2": require('@/assets/icons/chart-economic-b2.svg'),
				"C1": require('@/assets/icons/chart-economic-c1.svg'),
				"C1A": require('@/assets/icons/chart-economic-c1a.svg'),
				"C1B": require('@/assets/icons/chart-economic-c1b.svg'),
				"C2": require('@/assets/icons/chart-economic-c2.svg'),
				"C3": require('@/assets/icons/chart-economic-c3.svg'),
				"C+": require('@/assets/icons/chart-economic-c-plus.svg'),
				"C": require('@/assets/icons/chart-economic-c.svg'),
				"C-": require('@/assets/icons/chart-economic-c-less.svg'),
				"D+": require('@/assets/icons/chart-economic-d-plus.svg'),
				"D": require('@/assets/icons/chart-economic-d.svg'),
				"D1": require('@/assets/icons/chart-economic-d1.svg'),
				"D2": require('@/assets/icons/chart-economic-d2.svg'),
				"D2E": require('@/assets/icons/chart-economic-d2e.svg'),
				"D/E": require('@/assets/icons/chart-economic-d-e.svg'),
				"E": require('@/assets/icons/chart-economic-e.svg'),
				"M+": require('@/assets/icons/chart-economic-m-plus.svg'),
				"M": require('@/assets/icons/chart-economic-m.svg'),
				"M-": require('@/assets/icons/chart-economic-m-less.svg'),
				"1": require('@/assets/icons/chart-economic-1.svg'),
				"2": require('@/assets/icons/chart-economic-2.svg'),
				"3": require('@/assets/icons/chart-economic-3.svg'),
				"4": require('@/assets/icons/chart-economic-4.svg'),
				"5": require('@/assets/icons/chart-economic-5.svg'),
				"6": require('@/assets/icons/chart-economic-6.svg'),
				"unknown": require('@/assets/icons/chart-unknown.svg'),
			}

			const { niv_socio } = this.getAudience;

			const source = niv_socio.source.map(item => ({
				...item,
				src: socioEconomicImages[item.name.split(" ")[0].toUpperCase()] || socioEconomicImages["unknown"]
			}))

			return Object.assign({}, { ...niv_socio, source })
		}
	},
	methods: {
		...mapActions("audience", [
			"addFilter", "removeFilter"
		])
	},
	watch: {},
});
