import Vue from "vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardSearcher from "@/components/Content/CardSearcher.vue";
import { isEmpty } from "lodash";
import { mapActions } from "vuex";// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { convLocaleString } from "@/utils/convert";

export default Vue.extend({
	name: "UserGenreTable",
	model: {},
	props: {
		textColor: {
			type: String,
			default: "#5958a5",
		},
		source: {
			type: Object,
			default: function () {
				return {
					header: [],
					items: [],
				};
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		infoData: {
			type: Object,
			default: function () {
				return {};
			},
		},
		showFilters: {
			type: Boolean,
			default: true,
		},
	},
	components: { CardTextField, CardSearcher },
	data: () => ({
		expanded: false,
		page: 1,
		pageCount: 0,
		itemsPerPage: 50,
		search: "",
		showPagination: false
	}),
	created() {
		this.$nextTick(async () => {
			// console.log("UserGenreTable", {
			// 	loading: this.loading,
			// 	source: this.source,
			// 	infoData: this.infoData,
			// });
		});
	},
	async mounted() {},
	computed: {
		hasItems() {
			return !isEmpty(this.source?.items);
		},
		// getItems() {
		// 	var order = ["name", "F_18_24", "F_25_34", "F_35_44"];
		// 	const items: Array<any> = this.source.items;
		// 	const sorted: Array<any> = getSortedPoints(items, order, "name");
		// 	return sorted;
		// },
		lengthItems() {
			return this.source?.items.length;
		},
		getItemsPerPages() {
			return [
				{
					key: 15,
					value: 15,
				},
				{
					key: 50,
					value: 50,
				},
				{
					key: 100,
					value: 100,
				},
				// {
				// 	key: -1,
				// 	value: "ALL",
				// },
			];
		},
		getColspan() {
			let male = 0;
			let female = 0;

			if(this.source.items) {
				Object.keys(this.source.items[0]).forEach(key => {
					if(key.includes("F_")) female++
					if(key.includes("M_")) male++
				})
			}
			return { male, female }
		},
		getNumericHeaders() {
			// Return only numeric headers
			return this.source.headers.filter(item => (!["marca", "nombre_poi"].includes(item.value)));
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("download", ["downloadFile"]),
		colorClass(value: string) {
			let grad = 5;
			let type = "lighten";
			let color = "grey";

			if (value == "F_") {
				type = "lighten";
				grad = 3;
				color = "grey";
			}

			if (value == "M_") {
				type = "lighten";
				grad = 3;
				color = "grey";
			}

			return `${color} ${type}-${grad}`;
		},
		handlePagination(event: any) {
			this.itemsPerPage = event.itemsPerPage;
			this.page = event.page;
			this.pageCount = event.pageCount;
		},
		sumField(key: string) {
			return convLocaleString(this.source?.items.reduce(
				(a: any, b: any) => a + (b[key] || 0),
				0
			));
		},
		async handleDownload() {
			await this.setLoadingData(TypeLoading.loading);

			const params = {
				filters: undefined,
				title: "users_by_pois_gender_age",
				type: "gender_age",
				method: "GET"
			};

			await this.downloadFile(params);

			await this.setLoadingData();
		},
		formatNumber(number) {
			return convLocaleString(number);
		}
	},
	watch: {},
});
