import Vue from "vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardSearcher from "@/components/Content/CardSearcher.vue";
import { isEmpty, isNumber } from "lodash";
import { mapActions } from "vuex";// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { convLocaleString } from "@/utils/convert";

export default Vue.extend({
	name: "RichAttributionsTable",
	model: {},
	props: {
		textColor: {
			type: String,
			default: "#5958a5",
		},
		source: {
			type: Object,
			default: function () {
				return {
					header: [],
					items: [],
				};
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		infoData: {
			type: Object,
			default: function () {
				return {};
			},
		},
		showFilters: {
			type: Boolean,
			default: true,
		},
	},
	components: { CardTextField, CardSearcher },
	data: () => ({
		expanded: false,
		page: 1,
		pageCount: 0,
		itemsPerPage: 50,
		search: "",
		showPagination: false
	}),
	created() {
		this.$nextTick(async () => {
		});
	},
	async mounted() {},
	computed: {
		hasItems() {
			return !isEmpty(this.source?.items);
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("download", ["downloadFile"]),

		async handleDownload() {
			await this.setLoadingData(TypeLoading.loading);

			const params = {
				filters: undefined,
				title: this.infoData.id,
				type: this.infoData.id,
				method: "GET"
			};

			await this.downloadFile(params);

			await this.setLoadingData();
		},
		formatNumber(number: number) {
			return convLocaleString(number);
		},	

		mustFormatNumber(value: any): value is number {
			return isNumber(value);
		},
	},
	watch: {},
});
