import Vue from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";

export default Vue.extend({
	name: "PoisAndMobility",
	props: {},
	components: { CollapsePanel },
	data: () => ({
		panel: 0
	}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {},
	methods: {},
	watch: {},
});
