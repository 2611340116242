import Vue from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardLine from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Line/index.vue";
import UserGenreTable from "@/views/Admin/Persons/V10/Dependencies/Tables/UserGenre/index.vue";
import CardHeatmap from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Heatmap/index.vue";
import { mapActions, mapGetters } from "vuex";
import { orderBy, sortBy } from "lodash";

export default Vue.extend({
	name: "GenderAndAge",
	props: {},
	components: { CollapsePanel, CardLine, CardHeatmap, UserGenreTable },
	data: () => ({}),
	created() { },
	mounted() {
		this.$nextTick(async () => { });
	},
	computed: {
		...mapGetters("audience", [
			"getAudience"
		]),
		...mapGetters("audience", {
			getFilters: "getFilters"
		}),

		metrics_by_brand_and_poi() { return this.getAudience.metrics_by_brand_and_poi },

		getSource() {
			let items = {};
			let keys = ["F_TOTAL", "M_TOTAL", "T_TOTAL"];
			this.metrics_by_brand_and_poi.source.forEach(item => {
				let key = `${item.gender}_${item.age}`;
				if(!keys.some(value => value == key)) keys.push(key);

				if (!items[item.nombre_poi]) {
					items[item.nombre_poi] = {
						marca: item.marca,
						nombre_poi: item.nombre_poi,
						[key]: item.uniques,
						F_TOTAL: 0,
						M_TOTAL: 0,
						[`${item.gender}_TOTAL`]: item.uniques,
						T_TOTAL: item.uniques
					}
				} else {
					items[item.nombre_poi][key] = item.uniques;					
					items[item.nombre_poi][`${item.gender}_TOTAL`] += item.uniques;
					items[item.nombre_poi]["T_TOTAL"] += item.uniques;
				}
			})

			let objectKeys = {};
			let headers: {
				key: string,
				value: string,
				text: string,
				sortable: boolean
			}[] = [];

			keys.forEach(key => {
				objectKeys[key] = 0;

				if(key === "T_TOTAL") return;

				headers.push({
					key: key,
					value: key,
					text: this.$t(`Persons10.charts.metrics_by_brand_and_poi.${key}`),
					sortable: false
				})
			})

			headers = [{
				key: 'marca',
				value: 'marca',
				sortable: false,
				text: this.$t('Persons10.charts.metrics_by_brand_and_poi.marca')
			},{
				key: 'nombre_poi',
				value: 'nombre_poi',
				sortable: false,
				text: this.$t('Persons10.charts.metrics_by_brand_and_poi.nombre_poi')
			},
				...sortBy(headers, ['key'])
			];

			return {
				items: orderBy(Object.keys(items).map(key => {
					return Object.assign({}, objectKeys, items[key])
				}), ['T_TOTAL'], ['desc']).slice(0, 50),
				headers
			}
		}
	},
	methods: {
		...mapActions("audience", [
			"addFilter", "removeFilter"
		]),
	},
	watch: {},
});
