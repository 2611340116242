import Vue from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardGrouped from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Grouped/index.vue";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import { formatVisibleItems } from "../../../Utils";

export default Vue.extend({
	name: "ConnectionType",
	props: {},
	components: { CollapsePanel, CardGrouped },
	data: () => ({}),
	created() { },
	mounted() {
		this.$nextTick(async () => { 
		 });
		 const { carrier, device_type, make, browser, os } = this.getAudience;
		this.$watch(() => [carrier, device_type, make, browser, os], async() => {			
			await this.$nextTick();
			const items: HTMLCollection = this.$refs.connection_type.children;
			formatVisibleItems(items, [
				carrier, 
				device_type, 
				make, 
				browser, 
				os
			])			
		}, { immediate: true, deep: true })
	},
	computed: {
		...mapGetters("audience", [
			"getAudience"
		]),
		...mapGetters("audience", {
			getFilters: "getFilters"
		}),
		
		carrier() { return this.getAudience.carrier },
		device_type() { return this.getAudience.device_type },
		make() { return this.getAudience.make },
		browser() { return this.getAudience.browser },
		os() { return this.getAudience.os },
		sourceDate() {
			return this.date
		},
	},
	methods: {
		...mapActions("audience", [
			"addFilter", "removeFilter"
		]),
	},
	watch: {},
});
