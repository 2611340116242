var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('collapse-panel',{staticClass:"mb-1 exportable",attrs:{"icon":"mdi-calendar-range-outline","title":_vm.$t('Persons10.panelAudience.titles.date'),"chips":_vm.getFilters(['date_of_week', 'time_of_day'])},on:{"on_close_chip":_vm.removeFilter}},[_c('v-card',{staticClass:"gray lighten-3 px-5 pb-5",attrs:{"elevation":"0"}},[_c('v-row',{ref:"date_day_time"},[_c('v-col',[_c('card-line',{attrs:{"infoData":{
					id: 'date',
					name: _vm.$t('Persons10.panelAudience.charts.titles.date'),
				},"loading":_vm.date.loading,"hide_filter":"","source":_vm.date.source}})],1),_c('v-col',[_c('card-line',{attrs:{"infoData":{
					id: 'date_of_week',
					name: _vm.$t('Persons10.panelAudience.charts.titles.day_of_week'),
				},"loading":_vm.date_of_week.loading,"filters":_vm.getFilters('date_of_week'),"source":_vm.date_of_week.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-line',{attrs:{"infoData":{
					id: 'time_of_day',
					name: _vm.$t('Persons10.panelAudience.charts.titles.hour_of_day'),
				},"loading":_vm.time_of_day.loading,"filters":_vm.getFilters('time_of_day'),"source":_vm.time_of_day.source},on:{"filter":_vm.addFilter}})],1)],1),_c('div',{staticClass:"chart-section"},[_c('card-heatmap',{attrs:{"infoData":{
				id: 'time_of_day_of_week',
				name: _vm.$t('Persons10.panelAudience.charts.titles.heatmap'),
			},"labels":['hour_of_day', 'day_of_week'],"loading":_vm.time_of_day_of_week.loading,"filters":_vm.getFilters('time_of_day_of_week'),"source":_vm.time_of_day_of_week.source},on:{"filter":_vm.addFilter}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }