import Vue from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardLine from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Line/index.vue";
import AttributionsTable from "@/views/Admin/Persons/V10/Dependencies/Tables/Attributions/index.vue";
import CardHeatmap from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Heatmap/index.vue";
import { mapActions, mapGetters } from "vuex";
import { isEmpty, orderBy, sortBy } from "lodash";
import { GraphicEntity } from "@/models/persons/v10/Implements";
import { AttributionsItem } from "@/interfaces/graphic";

export default Vue.extend({
	name: "Attributions",
	props: {},
	components: { CollapsePanel, CardLine, CardHeatmap, AttributionsTable },
	data: () => ({}),
	created() { },
	mounted() {
		this.$nextTick(async () => { });
	},
	computed: {
		...mapGetters("audience", [
			"getAudience"
		]),
		...mapGetters("audience", {
			getFilters: "getFilters"
		}),

		attributions() { return this.getAudience.attributions },

		showAttributionTable() {
			return this.attributions.loading || !isEmpty(this.attributions.source)
		},

		getAttributions() {
			const data: GraphicEntity<AttributionsItem> = this.attributions

			// For store the items
			let items = {};
			// For store header keys
			let keys: string[] = [];

			// Make the logic and sum of totals
			data.source.forEach(item => {
				let col = item.poi_1;
				let row = item.poi_2;
				if (!keys.some(value => value == col)) keys.push(col);

				// If the key doesn't exist, create a new one
				if (!items[row]) {
					items[row] = {
						name: row,
						brand: item.marca_poi_1,
						[col]: item.devices,
						total: item.devices,
					}
				} 
				// Otherwhise add a new key and sum the total
				else {
					items[row][col] = item.devices;
					items[row]["total"] += item.devices;
				}
			})

			// create the headers array
			let headers: {
				key: string,
				value: string,
				text: string,
				sortable: boolean,
				class?: string
			}[] = [];

			// Push every item on it
			keys.forEach(key => {
				headers.push({
					key: key,
					value: key,
					text: key,
					sortable: true
				})
			})

			// Force the first column to be the name
			headers = [
				{ 
					key: "brand",
					value: "brand",
					text: this.$t('Persons10.charts.metrics_by_brand_and_poi.marca'),
					sortable: true,
					class: "headerAttrTable",
				},
				{ 
					key: "name",
					value: "name",
					text: this.$t('Persons10.panelAudience.charts.titles.pois'),
					sortable: true,
					class: "headerAttrTable",
				},
				...headers
			]

			// Return the data table
			return {
				items: orderBy(Object.keys(items).map(key => {
					return items[key]
				}), "total", "desc"),
				headers
			}
		}
	},
	methods: {
		...mapActions("audience", [
			"addFilter", "removeFilter"
		]),
	},
	watch: {},
});
