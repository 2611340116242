import { ItemGraphic } from "@/interfaces/graphic";
import { GraphicEntity } from "@/models/persons/v10/Implements";


export interface Audience {
	total_reach: GraphicEntity,
	proximity_to_poi: GraphicEntity,
	gender: GraphicEntity,
	age: GraphicEntity,
	niv_socio: GraphicEntity,

	filters: FilterItem[];
	last_filters: FilterItem[];
}

export interface IFiltersObject {
	poi_distance: FilterItem[];
	user_type: FilterItem[];
	gender: FilterItem[];
	age: FilterItem[];
	category_poi: FilterItem[];
	sub_category_poi: FilterItem[];
	marca_poi: FilterItem[];
	dpto_poi: FilterItem[];
	city_poi: FilterItem[];
	barrio_poi: FilterItem[];
	date: FilterItem[];
	date_of_week: FilterItem[];
	time_of_day: FilterItem[];
	residence_city: FilterItem[];
	residence_barrio: FilterItem[];
	iab: FilterItem[];
	interest: FilterItem[];
	sites: FilterItem[];
	app_name: FilterItem[];
	content_language: FilterItem[];
	city_connection: FilterItem[];
	niv_socio: FilterItem[];
	carrier: FilterItem[];
	device_type: FilterItem[];
	make: FilterItem[];
	browser: FilterItem[];
	os: FilterItem[];
}

export type FilterItem = Required<Pick<ItemGraphic, "name" | "type">>

export type FilterParam = {
	filters: {
		user_id_type: string[],
		// start_date?: string,
		// end_date?: string,
		radio?: number,
		frequency?: {
			[key: string]: number[]
		},
		pois: {
			city_poi: string[],
			dpto: string[],
			categoria: string[],
			subcategoria: string[],
			marca: string[],
			nombre_poi: string[]
		},
		bid_request: {
			app_bundle: string[],
			app_name: string[],
			city_seen: string[],
			domain: string[],
			iab: string[],
			platform_browser: string[],
			platform_device_language: string[],
			platform_device_make: string[],
			platform_device_type: string[],
			platform_os: string[],
			carrier: string[]
		},
		gender_age: {
			gender: string[],
			age: string[]
		},
		home: {
			neighborhood_residence: string[],
			city_residence: string[],
			nombre_estado: string[],
			nivsocio: string[]
		},
		interest: {
			interest: string[]
		}
	}
}

export interface ISegmentAudience {
	name: string;
	categories: number[];
	advertiser_id: number | null;
}

export interface FloatingAction {
	action: string;
	title: string;
	icon: string;
	loading: boolean;
	disabled: boolean;
}

export enum AUDIENCE_ACTIONS {
	FILTER = "filter",
	BACK = "back",
	EXPORT_SCREEN = "export_screen",
	EXPORT_AUDIENCE = "export_audience",
}

export interface IExportSegmentAudience extends ISegmentAudience, FilterParam { }