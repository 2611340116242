var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showRichAttributionTable)?_c('collapse-panel',{staticClass:"mb-1",attrs:{"icon":"mdi-radar","title":_vm.$t('Persons10.panelAudience.titles.rich_attributions'),"chips":[]}},[_c('v-card',{staticClass:"gray lighten-3 px-2 pb-5",attrs:{"elevation":"0"}},[_c('div',{staticClass:"chart-section"},[_c('rich-attributions-table',{attrs:{"infoData":{
				id: 'rich_attributions_totals',
				name: _vm.$t('Persons10.panelAudience.charts.titles.rich_attributions_totals'),
			},"source":_vm.getRichAttributions(_vm.rich_attributions),"loading":_vm.rich_attributions.loading}}),_c('rich-attributions-table',{attrs:{"infoData":{
				id: 'rich_attributions_daily',
				name: _vm.$t('Persons10.panelAudience.charts.titles.rich_attributions_daily'),
			},"source":_vm.getRichAttributions(_vm.rich_attributions_daily),"loading":_vm.rich_attributions_daily.loading}})],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }