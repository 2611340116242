import Vue from "vue";
//@ts-ignore
import { getError } from "@/utils/resolveObjectArray";
//@ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
//@ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
//@ts-ignore
import Alertize from "@/components/Alertize.vue";
//@ts-ignore
import { isRequired } from "@/services/rule-services";
//@ts-ignore
import { SortingOption } from "@/interfaces/paginated";
//@ts-ignore
import { SegmentCategoryFilter } from "@/interfaces/segment_category";
import { isEmpty, isNull, filter } from "lodash";
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
import CardActions from "@/components/Content/CardActions.vue";

export default Vue.extend({
	name: "AudienceExport",

	props: {
		show: {
			type: Boolean,
			default: false
		}
	},

	components: {
		CardTextField,
		CardAutocomplete,
		Alertize,
    DialogDefault,
    CardActions
	},

	data: () => ({
		selection: [],
		valid: true,
		name: "",
		advertiser_id: null,
		params_segment_category: {
			filters: {} as SegmentCategoryFilter,
			options: { sort: "name", order: "desc" } as SortingOption,
		},
		list_categorias: [],
		items: [],
		rule_name: [],
	}),

	created() {},

	async mounted() {
		this.$nextTick(async () => {
			await this.fetchAdvertiser();
			this.showModalSegment();
		});
	},

	computed: {
		getAdvertisers() {
			return this.$store.state.advertiser.advertisers_list;
		},

		getErrors() {
			return this.$store.state.proccess.errors;
		},

		getRules() {
			return {
				isRequired,
			};
		},
	},

	methods: {
		aplyRules() {
			this.rule_name = [this.getRules.isRequired];
		},

		clearFields() {
			this.name = "";
			this.advertiser_id = null;
		},

		clearRules() {
			this.rule_name = [];
		},

		getErrorMessage(index: any) {
			return getError(this.getErrors, index);
		},

		setLoadingField(_loading: Boolean) {
			this.$store.state.proccess.loading_field = _loading;
		},
    handleAction(data: {type: "submit"|"cancel"}) {
      if(data.type === "submit") {
        this.handleSubmit();
      }
      if(data.type === "cancel") {
        this.closeModal();
      }
    },

		closeModal() {
			this.$emit("close");
		},

		async handleSubmit() {
			try {
				await this.aplyRules();

				if (!(await this.validate())) return;

				let params = {
					name: this.name,
					advertiser_id: this.advertiser_id,
					categories: this.getElementSelected(),
				};
				this.$emit("export", params);

				this.clearRules();
				this.clearFields();
			} catch (error) {
				this.clearRules();
			}
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

		async showModalSegment() {
			await this.setLoadingField(true);
			await this.dispatchSegmentCategory().then((response) => {
				this.list_categorias = response;
			});
			this.getSegmentCategoriesByParentID(this.list_categorias);
			await this.setLoadingField(false);
		},

		async updateCategories() {
			await this.setLoadingField(true);
			await this.dispatchSegmentCategory().then((response) => {
				this.list_categorias = response;
			});
			this.getSegmentCategoriesByParentID(this.list_categorias);
			await this.setLoadingField(false);
		},

		getElementSelected() {
			let values = new Array();
			if (isEmpty(this.selection)) return this.selection;
			this.selection.forEach((element) => {
				values.push(element.id);
			});
			return values;
		},

		/*
		 * Metodos del treeview
		 */

		getSegmentCategoriesByParentID(values: any) {
			this.items = [];
			if (isEmpty(values)) return;
			let parents = this.getSegmentCategoriesParent(null);
			parents.forEach((element) => {
				let hijos = this.recursividad(element.id, 1);
				let item = {
					id: element.id,
					name: element.name,
					parent_id: element.parent_id,
					advertiser_id: element.advertiser_id,
					children: hijos,
					extra: 1,
				};
				this.items.push(item);
			});
		},

		recursividad(PID: number, valor: number) {
			let segmentos = this.getSegmentCategoriesParent(PID);
			let arr = new Array();
			if (isEmpty(segmentos)) return;
			segmentos.forEach((element) => {
				let hijos = this.recursividad(element.id, valor + 1);
				let item = {
					id: element.id,
					name: element.name,
					parent_id: element.parent_id,
					advertiser_id: element.advertiser_id,
					children: hijos,
					extra: valor + 1,
				};
				arr.push(item);
				if (!isNull(element.parent_category)) {
					this.recursividad(element.id, valor + 1);
				}
			});
			return arr;
		},

		getSegmentCategoriesParent(params: any) {
			return filter(this.list_categorias, { parent_id: params });
		},

		/*
		 * Metodos Fetch
		 */

		async fetchAdvertiser() {
			try {
				await this.setLoadingField(true);
				await this.dispatchAdvertisers();
				await this.setLoadingField(false);
			} catch (error) {
				await this.setLoadingField(false);
			}
		},

		/*
		 * Metodos Dispatch
		 */

		async dispatchAdvertisers() {
			return this.$store.dispatch("advertiser/list", {
				filters: { active: true },
				options: { sort: "name", order: "asc" },
			});
		},

		async dispatchSegmentCategory() {
			if (isNull(this.advertiser_id)) {
				this.params_segment_category.filters = {};
			} else {
				this.params_segment_category.filters = {
					advertiser_id: this.advertiser_id,
				};
			}
			return this.$store.dispatch(
				"segment_category/all",
				this.params_segment_category,
				{
					root: true,
				}
			);
		},
	},

	watch: {},
});