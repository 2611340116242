/**
 * Allowed country codes to be filtered by socioeconomic
 */
export const allowedFiltrableSocioeconomic = [
  484,  // México
  32,   // Argentina
  152,  // Chile
  604,  // Peru
  170,  // Colombia
  218,  // Ecuador
]