import Vue from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardGrouped from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Grouped/index.vue";
import CampaignSummary from "@/views/Admin/Persons/V10/Dependencies/Tables/CampaignSummary/index.vue";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";

export default Vue.extend({
	name: "CampaignsAudience",
	props: {},
	components: { CollapsePanel, CampaignSummary },
	data: () => ({}),
	created() { },
	mounted() {
		this.$nextTick(async () => { 
		 });
	},
	computed: {
	},
	methods: {},
	watch: {},
});
