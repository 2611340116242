var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('collapse-panel',{staticClass:"mb-1 exportable",attrs:{"icon":"mdi-home-city-outline","title":_vm.$t('Persons10.panelAudience.titles.residence_place'),"chips":_vm.getFilters(['residence_city', 'residence_barrio'])},on:{"on_close_chip":_vm.removeFilter}},[_c('v-card',{staticClass:"gray lighten-3 px-5 pb-5",attrs:{"elevation":"0"}},[_c('v-row',{ref:"residenceplace"},[_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'residence_city',
					name: _vm.geoTranslate('cities')
				},"loading":_vm.residence_city.loading,"filters":_vm.getFilters('residence_city'),"source":_vm.residence_city.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'residence_barrio',
					name: _vm.geoTranslate('neighborhoods')
				},"loading":_vm.residence_barrio.loading,"filters":_vm.getFilters('residence_barrio'),"source":_vm.residence_barrio.source},on:{"filter":_vm.addFilter}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }