var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('collapse-panel',{staticClass:"mb-1 exportable",attrs:{"icon":"mdi-cellphone-link","title":_vm.$t('Persons10.panelAudience.titles.digital_behavior'),"chips":_vm.getFilters(['iab', 'interest', 'sites', 'app_name', 'content_language', 'city_connection'])},on:{"on_close_chip":_vm.removeFilter}},[_c('v-card',{staticClass:"gray lighten-3 px-5 pb-5",attrs:{"elevation":"0"}},[_c('v-row',{ref:"digitalbehavior"},[_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
				id: 'iab',
				name: _vm.$t('Persons10.panelAudience.charts.titles.navigation_behavior'),
			},"loading":_vm.iab.loading,"filters":_vm.getFilters('iab'),"source":_vm.iab.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'interest',
					name: _vm.$t('Persons10.panelAudience.charts.titles.interests'),
				},"loading":_vm.interest.loading,"filters":_vm.getFilters('interest'),"source":_vm.interest.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'sites',
					name: _vm.$t('Persons10.panelAudience.charts.titles.visited_websites'),
				},"loading":_vm.sites.loading,"filters":_vm.getFilters('sites'),"source":_vm.sites.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'app_name',
					name: _vm.$t('Persons10.panelAudience.charts.titles.visited_apps'),
				},"loading":_vm.app_name.loading,"filters":_vm.getFilters('app_name'),"source":_vm.app_name.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'content_language',
					name: _vm.$t('Persons10.panelAudience.charts.titles.setup_language'),
				},"loading":_vm.content_language.loading,"filters":_vm.getFilters('content_language'),"source":_vm.content_language.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'city_connection',
					name: _vm.geoTranslate,
				},"loading":_vm.city_connection.loading,"filters":_vm.getFilters('city_connection'),"source":_vm.city_connection.source},on:{"filter":_vm.addFilter}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }