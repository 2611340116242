var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('collapse-panel',{staticClass:"mb-1 exportable",attrs:{"icon":"mdi-wifi","title":_vm.$t('Persons10.panelAudience.titles.connection_type'),"chips":_vm.getFilters(['carrier', 'device_type', 'make', 'browser', 'os'])},on:{"on_close_chip":_vm.removeFilter}},[_c('v-card',{staticClass:"gray lighten-3 px-5 pb-5",attrs:{"elevation":"0"}},[_c('v-row',{ref:"connection_type"},[_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'carrier',
					name: _vm.$t('Persons10.panelAudience.charts.titles.operator'),
				},"loading":_vm.carrier.loading,"filters":_vm.getFilters('carrier'),"source":_vm.carrier.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'device_type',
					name: _vm.$t('Persons10.panelAudience.charts.titles.device_type'),
				},"loading":_vm.device_type.loading,"filters":_vm.getFilters('device_type'),"source":_vm.device_type.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'make',
					name: _vm.$t('Persons10.panelAudience.charts.titles.manufacturer'),
				},"loading":_vm.make.loading,"filters":_vm.getFilters('make'),"source":_vm.make.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'browser',
					name: _vm.$t('Persons10.panelAudience.charts.titles.browser'),
				},"loading":_vm.browser.loading,"filters":_vm.getFilters('browser'),"source":_vm.browser.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'os',
					name: _vm.$t('Persons10.panelAudience.charts.titles.os'),
				},"loading":_vm.os.loading,"filters":_vm.getFilters('os'),"source":_vm.os.source},on:{"filter":_vm.addFilter}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }