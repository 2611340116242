import Vue, { PropType } from "vue";
import CollapsePanel from "@/components/Content/Collapse/Panel.vue";
import { translateChartIfExist } from "@/utils/Locale";

export default Vue.extend({
	name: "CollapsePanelPersona",
	props: {
		title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Array as PropType<{ type: string, name: string }[]>
    },
    id: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    }
	},
	components: {
		CollapsePanel
	},
	data: () => ({}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {},
	methods: {
    deleteFilter(chip) {
      this.$emit('on_close_chip', chip)
    },
    translate(type: string, value: any) {
      return translateChartIfExist(type, value);
    }
  },
  watch: {},
});
