import Vue from "vue";

import AmchartLine from "@/views/Admin/Persons/V10/Dependencies/Amcharts/utils/line";
import { isEmpty } from "lodash";
import { ItemGraphic } from "@/interfaces/graphic";
import { OrderOption } from "@/interfaces/audience";

const slug: string = "line";

export default Vue.extend({
	name: "LineChart",
	model: {
		event: "input",
		prop: "filter",
	},
	props: {
		source: {
			type: Array,
			default: function () {
				return [] as Array<ItemGraphic>;
			},
		},
		filter: {
			type: Object,
			default: function () {
				return {
					sort: "",
					order: OrderOption.DESC,
				};
			},
		},
		top: {
			type: Object,
			default: function () {
				return {};
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		infoData: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: {},
	data: () => ({
		dinamic_id: "",
	}),
	created() {
		this.$nextTick(async () => {
			this.dinamic_id = `${this.infoData.id}_${slug}`;
		});
	},
	async mounted() {
		try {
			if (isEmpty(this.source)) return;
			this.dinamic_id = `${this.infoData.id}_${slug}`;
			this.loadSource(this.source, this.filter, this.top);
		} catch (error) {
			console.error("loadSource", { error });
		}
	},
	computed: {},
	methods: {
		async loadSource(
			source: Array<any>
		) {
			/**
			 * Estructura del array
			 * [{ type, name, unique }, { type, name, unique }, ...]
			 */
			AmchartLine.setup({
				id: this.dinamic_id,
				infoData: this.infoData,
				source
			});
		},
	},
	watch: {
		async source(val) {
			await AmchartLine.dispose(this.dinamic_id);
			this.loadSource(val, this.modelFilter, this.top?.key);
		},
	},
});
