import Vue from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardLine from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Line/index.vue";
import CardHeatmap from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Heatmap/index.vue";
import { mapActions, mapGetters } from "vuex";
import { formatVisibleItems } from "../../../Utils";

export default Vue.extend({
	name: "DateAudience",
	props: {},
	components: { CollapsePanel, CardLine, CardHeatmap },
	data: () => ({}),
	created() { },
	mounted() {
		this.$nextTick(async () => { });
		const { date_of_week, time_of_day, date } = this.getAudience;
		this.$watch(() => [date_of_week, time_of_day, date], async () => {
			await this.$nextTick();
			const items: HTMLCollection = this.$refs.date_day_time.children;
			formatVisibleItems(items, [
				date_of_week, 
				time_of_day, 
				date
			])			
		}, { immediate: true, deep: true })
	},
	computed: {
		...mapGetters("audience", [
			"getAudience"
		]),
		...mapGetters("audience", {
			getFilters: "getFilters"
		}),
		
		date_of_week() { return this.getAudience.date_of_week },
		time_of_day() { return this.getAudience.time_of_day },
		date() { return this.getAudience.date },
		time_of_day_of_week() { return this.getAudience.time_of_day_of_week },
	},
	methods: {
		...mapActions("audience", [
			"addFilter", "removeFilter"
		]),
	},
	watch: {},
});
