import Vue from "vue";

export default Vue.extend({
	name: "CollapsePanel",
	props: {
		hideActions: {
			type: Boolean,
			default: false
		},
		panel: {
			type: Number,
			default: 0
		},
		disabled: {
			type: Boolean,
			default: false
		},
		expanded: {
			type: Boolean,
			default: false
		},
	},
	components: {},
	data: () => ({}),
	created() {},
	async mounted() {},
	computed: {},
	methods: {},
	watch: {},
});
